/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = () => {
  // removing class open-menu whenver page changes to fix scroll issue
 document.body.classList.remove('open-menu')
}
